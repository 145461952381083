<template>
  <main
    class="color-primary h-100 blank-aside-js w-100"
    :class="isOpenAside == 'true' ? 'blank-aside' : ''"
  >
    <TourismFilter :hasTypeDeal="false"></TourismFilter>
    <section class="tourism-product__gallery">
      <div class="tourism-product__image-wrapper tourism-product__image-wrapper-1">
          <img class="tourism-product__image" src="/assets/img/pool-hotel.png" alt="">
      </div>
      <div class="tourism-product__image-wrapper tourism-product__image-wrapper-2">
          <img class="tourism-product__image" src="/assets/img/hotel.png" alt="">
      </div>
      <div class="tourism-product__image-wrapper tourism-product__image-wrapper-3">
          <img class="tourism-product__image" src="/assets/img/hall.png" alt="">
      </div>
      <div class="tourism-product__image-wrapper tourism-product__image-wrapper-4">
          <img class="tourism-product__image" src="/assets/img/terrace.png" alt="">
      </div>
      <div class="tourism-product__image-wrapper tourism-product__image-wrapper-5">
          <img class="tourism-product__image" src="/assets/img/pool-croped.png" alt="">
      </div>

      <a class="link tourism-product__more-photo" href=""></a>
    </section>
    <section class="tourism-container">
      <div class="d-flex align-items-center justify-content-between flex-wrap mt-10">
        <div class="d-flex align-items-center">
          <h4>
            Flights - Tel-Aviv
          </h4>
          <img src="/assets/img/icons/flight.svg" alt="" class="mx-5">
          <h4>
            Berlin, Germany
          </h4>
        </div>
        <div class="d-flex fs14">
          <p class="mx-5">
            {{ $t( "Date range" ) }}: <b>01/04/2021 - 10/04/2021</b>
          </p>
          <p class="mx-5">
            {{ $t( "Duration" ) }}: <b>3 - 5 Nights</b> 
          </p>
          <p class="mx-5">
            {{ $t( "Travelers" ) }}: <b>2 Adults</b> 
          </p>
        </div>
        <div class="d-flex">
          <SortTourism @sortDeals="sortDeals" :isTourismDeal="true"></SortTourism>
          <FilterTourism></FilterTourism>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-md-3">
          <FlightDesItem>
            <button 
              class="primary-btn small-btn bg-blue5 df-c fs14 color-primary full-detail-btn mt-7" 
              slot="actionPart" 
              @click="openFullDetailModal()"
            >
              <img src="/assets/img/icons/info-white.svg" alt="" class="">
              {{ $t( "Full details" ) }}
            </button>
          </FlightDesItem>
        </div>
        <div class="col-md-3">
          <FlightDesItem>
            <button 
              class="primary-btn small-btn bg-blue5 df-c fs14 color-primary full-detail-btn mt-7" 
              slot="actionPart" 
              @click="openFullDetailModal()"
            >
              <img src="/assets/img/icons/info-white.svg" alt="" class="">
              {{ $t( "Full details" ) }}
            </button>
          </FlightDesItem>
        </div>
        <div class="col-md-3">
          <FlightDesItem>
            <button 
              class="primary-btn small-btn bg-blue5 df-c fs14 color-primary full-detail-btn mt-7" 
              slot="actionPart" 
              @click="openFullDetailModal()"
            >
              <img src="/assets/img/icons/info-white.svg" alt="" class="">
              {{ $t( "Full details" ) }}
            </button>
          </FlightDesItem>
        </div>
        <div class="col-md-3">
          <FlightDesItem>
            <button 
              class="primary-btn small-btn bg-blue5 df-c fs14 color-primary full-detail-btn mt-7" 
              slot="actionPart" 
              @click="openFullDetailModal()"
            >
              <img src="/assets/img/icons/info-white.svg" alt="" class="">
              {{ $t( "Full details" ) }}
            </button>
          </FlightDesItem>
        </div>
        <div class="col-md-3">
          <FlightDesItem>
            <button 
              class="primary-btn small-btn bg-blue5 df-c fs14 color-primary full-detail-btn mt-7" 
              slot="actionPart" 
              @click="openFullDetailModal()"
            >
              <img src="/assets/img/icons/info-white.svg" alt="" class="">
              {{ $t( "Full details" ) }}
            </button>
          </FlightDesItem>
        </div>
        <div class="col-md-3">
          <FlightDesItem>
            <button 
              class="primary-btn small-btn bg-blue5 df-c fs14 color-primary full-detail-btn mt-7" 
              slot="actionPart" 
              @click="openFullDetailModal()"
            >
              <img src="/assets/img/icons/info-white.svg" alt="" class="">
              {{ $t( "Full details" ) }}
            </button>
          </FlightDesItem>
        </div>
        <div class="col-md-3">
          <FlightDesItem>
            <button 
              class="primary-btn small-btn bg-blue5 df-c fs14 color-primary full-detail-btn mt-7" 
              slot="actionPart" 
              @click="openFullDetailModal()"
            >
              <img src="/assets/img/icons/info-white.svg" alt="" class="">
              {{ $t( "Full details" ) }}
            </button>
          </FlightDesItem>
        </div>
        <div class="col-md-3">
          <FlightDesItem>
            <button 
              class="primary-btn small-btn bg-blue5 df-c fs14 color-primary full-detail-btn mt-7" 
              slot="actionPart" 
              @click="openFullDetailModal()"
            >
              <img src="/assets/img/icons/info-white.svg" alt="" class="">
              {{ $t( "Full details" ) }}
            </button>
          </FlightDesItem>
        </div>
      </div>
    </section>
    <FullDetailModal :flight_data="{}"></FullDetailModal>
  </main>
</template>

<script>
import BannerSection from "../../../components/BannerSection";
import TourismFilter from "../../../components/tourism/TourismFilter";
import FilterTourism from "../../../components/tourism/FilterTourism";
import SortTourism from "../../../components/tourism/SortTourism";
import FlightDesItem from "../../../components/tourism/flight/FlightDesItem";
import FullDetailModal from "../../../components/tourism/flight/FullDetailModal.vue";

export default {
  name: "FlightDestinationDates",
  components: {
    BannerSection,
    TourismFilter,
    FilterTourism,
    SortTourism,
    FlightDesItem,
    FullDetailModal
  },
  methods: {
    sortDeals() {
    },
    openFullDetailModal() {
      this.$modal.show("FullDetailModal");
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../assets/scss/tourism.scss";
</style>